.home-experience-root {
  margin: 40px 0px;
  padding: 20px;
}

.home-experience-header {
  font-size: 3rem !important;
}

.home-experience-header-mobile {
  font-size: 2em !important;
}

.home-experience-carousel {
  margin: 40px 0px;
}
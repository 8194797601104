html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1 {
  font-size: 3rem !important;
}

p {
  font-size: 18px;
}
.home-main-root {
  height: 1260px;
  padding-top: 80px;
  background: url("../images/jordan_boat_cruise.jpg") no-repeat center;
  background-size: cover;
  display: 'flex';
}

.home-main-container {
  padding-top: 10%;
}

.home-main-desc {
  font-size: 65px;
  color: #000;
}

.home-main-root-mobile {
  height: auto;
  padding-top: 80px;
  background: url("../images/jordan_boat_cruise.jpg") no-repeat center;
  background-size: cover;
  display: flex;
}

.home-main-container-mobile {
  padding-top: 10%;
  padding-right: 40%;
  padding-bottom: 220px;
}

.home-main-desc-mobile {
  font-size: 1.5rem;
  color: #000;
}
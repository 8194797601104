.experience-main {
  height: 200px;
  padding-top: 80px;
  background: url("../images/jordan_boat_cruise.jpg") no-repeat center;
  background-size: cover;
  background-position: center top;
  display: 'flex';
}

.experience-main-mobile {
  height: 140px;
  padding-top: 80px;
  background: url("../images/jordan_boat_cruise.jpg") no-repeat center;
  background-size: cover;
  background-position: center top;
}

.experience-root {
  min-height: 100%;
}

.experience-header {
  font-size: 3rem !important;
}

.experience-header-mobile {
  font-size: 2em !important;
}

.experience-grid {
  margin: 30px 0px !important;
}
.home-short-bio-root {
  margin: 40px 0px;
  padding: 20px;
}

.home-short-bio-header {
  font-size: 3rem !important;
}

.home-short-bio-header-mobile {
  font-size: 2em !important;
}